<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog_show"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-select
                    v-model="dataForm.kategorie"
                    :items="categories"
                    label="Kategorie"
                    :rules="[(v) => !!v || 'Kategorie musí být vybrána']"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <b>*Nadpis</b>
                  <v-text-field
                    v-model="dataForm.nazev"
                    :rules="nazevRules"
                    clearable
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <b>*Vložte nový úvodní obrázek</b>
                  <v-file-input
                    v-model="attachments"
                    v-loading
                    placeholder="Vložte úvodní obrázek"
                    accept="image/*"
                    @change="addImage"
                  ></v-file-input>
                  <v-input
                    v-model="dataForm.linkObrazek"
                    hidden
                  >
                  </v-input>
                  <b>*Link na video</b>
                  <v-text-field
                    v-model="dataForm.link"
                    clearable
                    :rules="[(v) => !!v || 'Link musí být zadán']"
                    required
                  ></v-text-field>
                </v-col>
                <!-- POPIS -->
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <b>*Text</b>
                  <tiptap-vuetify
                    v-model="dataForm.text"
                    placeholder="Zde pište..."
                    :toolbar-attributes="{ color: '#ffffff' }"
                    :card-props="{ flat: true, color: '#FBFBFB' }"
                    :extensions="extensions"
                  >
                  </tiptap-vuetify>
                </v-col>
                <!-- UVEREJNIT -->
                <v-col
                  sm="12"
                  xs="12"
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                >
                  <v-checkbox
                    v-model="dataForm.active"
                    label="Uveřejnit"
                    class="mt-0"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <!-- ODOSLAT EMAILOM -->
                <v-col
                  sm="12"
                  xs="12"
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                >
                  <v-checkbox
                    v-model="dataForm.email"
                    label="Odeslat emailem"
                    class="mt-0"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>polia označené * je potrebné vyplniť</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog(false)"
          >
            Zavřít
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="saveVideonavody(true)"
          >
            Uložit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint prefer-destructuring: ["error", {VariableDeclarator: {object: true}}] */
/* eslint-disable vue/prop-name-casing */

import axios from 'axios'
import {
  TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, Image,
}
from 'tiptap-vuetify'
import response from '@/mixins/response'

// import { _ } from '@/utils'

export default {
  components: {
    TiptapVuetify,
  },
  mixins: [response],
  props: {
    show: { type: Boolean, default: false },
    title: { type: String, default: 'Vložit nové video' },
    item_id: { type: String, default: undefined },
  },
  data() {
    return {
      extensions: [
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3],
          },
        }],
        Bold,
        HorizontalRule,
        Paragraph,
        HardBreak,
        Image,
      ],
      categories: ['Vše', 'Chat', 'Hireme', 'Inzerce', 'Neurčeno'],
      dialog_show: false,
      valid: true,
      default_dataForm: {
        nazev: '',
        link: '',
        text: '',
        active: true,
        email: true,
        linkObrazek: '',
        katerorie: 'Vše',
      },
      attachments: [],
      dataForm: {},
      nazevRules: [
        v => !!v || 'Nadpis je povinné pole!',
        v => (v && v.length >= 5) || 'Nadpis musí obsahovať aspoň 5 znakov!',
      ],
      popisRules: [
        v => !!v || 'Je nutné vyplnit text příspevku!',
      ],
    }
  },
  computed: {

  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        this.dialog_show = val
      },
    },
    item_id: {
      immediate: true,
      handler(val) {
        if (val) {
          this.detailVideonavody()
        }
      },
    },
  },
  created() {
    this.dialog_show = this.show
    this.dataForm = this.lh.cloneDeep(this.default_dataForm)
    if (this.item_id) { // jedna sa o UPDATE, nacitaj detail
      this.detailVideonavody()
    }
    if (this.type === 1) {
      this.extensions.pop()
    }
  },
  mounted() {

  },
  methods: {
    detailVideonavody() {
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/videonavody/${this.item_id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      axios(config)
        .then(resp => {
          if (resp.data.db_data[0]) {
            this.dataForm = resp.data.db_data[0]
          }
          console.log('resp', this.videonavody)
        })
        .catch(err => {
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snack_color = 'red'
          this.snackbar = true
        })
    },
    saveVideonavody() {
      if (this.$refs.form.validate()) {
        let config
        if (this.item_id) { // UPDATE
          config = {
            url: `${process.env.VUE_APP_BASE_API}/api/v2/videonavody/${this.item_id}`,
            data: JSON.stringify(this.dataForm),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'put',
          }
        } else { // CREATE
          config = {
            url: `${process.env.VUE_APP_BASE_API}/api/v2/videonavody`,
            data: JSON.stringify(this.dataForm),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'post',
          }
        }
        axios(config)
          .then(resp => {
            console.log('saveVideonavody => ', resp)
            this.closeDialog()
          })
          .catch(err => {
            console.error(err)
            this.snack_text = this.responseError(err)
            this.snack_color = 'red'
            this.snackbar = true
          })
      }
    },
    closeDialog(p_refresh) { // p_refresh true|false Ak je true, bude sa po zavreni dialogu volat refresh dat zo servera.
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
        this.$refs.form.reset()
        this.dataForm = this.lh.cloneDeep(this.default_dataForm)
      }
      this.$emit('close', { refresh: p_refresh })
    },
    addImage() {
      if (this.attachments && this.attachments !== '') {
        const reader = new FileReader()
        reader.readAsDataURL(this.attachments)
        reader.onload = () => {
          this.dataForm.linkObrazek = reader.result.toString()
        }
      }
    },
  },
}
</script>
