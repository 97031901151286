<template>
  <!--
    ************************************************************************************************
        v-card mala tabulka s obrazkom, nazvom videa a popisom, poctu zhliadnuti a hodnotenie
    ************************************************************************************************
  -->
  <div>
    <!-- VIDEO -->
    <v-card
      class="d-flex align-center"
      style="height:270px;margin-bottom:15px"
      @mouseover="small_show_btn=video._id"
      @mouseleave="small_show_btn=-1"
    >
      <div
        class="d-flex justify-space-between flex-wrap flex-md-nowrap flex-column flex-md-row"
        style="width:100%;overflow-y: scroll;height:250px;"
      >
        <div
          style="padding:10px;width:230px;"
          class="play-img"
        >
          <v-img
            width="200"
            height="100%"
            :src="video.linkObrazek"
            style="cursor:pointer"
            @click="handleClick(video._id, video.link)"
          ></v-img>
          <div
            class="play-icon"
            style="cursor:pointer;position:absolute;top:calc(50% - 30px);width:200px;text-align:center;"
            @click="handleClick(video._id, video.link)"
          >
            <v-icon
              x-large
            >
              {{ mdiPlay }}
            </v-icon>
            <div>
              <b>
                Přehrát
              </b>
            </div>
          </div>
        </div>
        <v-divider :vertical="$vuetify.breakpoint.mdAndUp"></v-divider>
        <div>
          <v-card-title>
            <div
              style="width:100%;"
            >
              <v-btn
                max-width="420"
                color="primary"
                text
                @click="handleClick(video._id, video.link)"
              >
                {{ video.nazev }}
              </v-btn>
            </div>
          </v-card-title>
          <!-- POPIS VIDEA -->
          <v-card-text
            v-html="video.text"
          >
          </v-card-text>
          <!-- RATING, ZHLIADNUTIA, TLACITKO: upravit/zmazat video -->
          <v-card-actions class="d-flex justify-space-between dense">
            <v-row>
              <v-col
                xl="6"
                lg="6"
                xs="12"
                sm="12"
                md="12"
              >
                <v-rating
                  v-model="video.rating"
                  color="warning"
                  background-color="warning"
                  dense
                  hover
                  class="me-3 flex-shrink-0"
                  @input="voteRating(video._id, video.rating)"
                ></v-rating>
              </v-col>
              <v-col
                v-if="video.impress && video.impress > 0"
                xl="6"
                lg="6"
                xs="12"
                sm="12"
                md="12"
              >
                <span>Zhlédnutí :</span> <span class="font-weight-bold">{{ video.impress }}</span>
              </v-col>
            </v-row>
          </v-card-actions>
        </div>
      </div>
      <div style="position:absolute;bottom:25px;right:30px">
        <!-- tlacitka -->
        <v-row
          v-show="(small_show_btn==video._id && $store.getters.getProfile._id === (video.autor && video.autor[0]._id)) || (small_show_btn==video._id && $store.getters.getProfile.is_admin)">
          <v-col
            xl="12"
            lg="12"
            xs="12"
            sm="12"
            md="12"
          >
            <!-- TLACITKO: upravit video -->
            <v-fab-transition>
              <v-btn
                color="primary"
                fab
                x-small
                style="margin-right:5px"
                @click="onEditFormVideonavody(video._id)"
              >
                <v-icon>{{ mdiPencil }}</v-icon>
              </v-btn>
            </v-fab-transition>
            <!-- TLACITKO: vymazat video -->
            <v-fab-transition>
              <v-btn
                color="error"
                fab
                x-small
                @click="onDeleteFormVideonavody(video._id)"
              >
                <v-icon>{{ mdiTrashCan }}</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- SNACKBAR -->
    <snack-bar
      :snackbar="snackbar"
      :color="snack_color"
      :text="snack_text"
      @close="snackbar=false"
    >
    </snack-bar>
    <!-- ZMAZAT DIALOG -->
    <v-dialog
      v-model="open_dialog_zmazat"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h7">
          Upozornění
        </v-card-title>

        <v-card-text>
          Skutečne chcete odstranit videonávod?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="open_dialog_zmazat = false"
          >
            Zrušit
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="deleteFormVideonavody()"
          >
            Odstranit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: ["off", { "allow": ["__place"] }] */
/* eslint no-unused-vars: "off" */
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiCartPlus,
  mdiShareVariantOutline,
  mdiLockOpenOutline,
  mdiStarOutline,
  mdiAccountOutline,
  mdiTrendingUp,
  mdiPencil,
  mdiTrashCan,
  mdiHelpCircleOutline,
  mdiPlay,
} from '@mdi/js'
import axios from 'axios'
import SnackBar from '@/components/snackbar/index.vue'
import response from '@/mixins/response'

export default {
  components: {
    'snack-bar': SnackBar,
  },
  mixins: [response],
  props: ['video'],
  data() {
    return {
      item_id: '',
      mdiPlay,
      mdiPencil,
      mdiTrashCan,
      snackbar: false,
      snack_color: 'red',
      snack_text: '',
      open_dialog_zmazat: false,
      small_show_btn: -1,
    }
  },
  created() {
    console.log('[Video.vue] *** created')
  },
  methods: {
    handleClick(p_id, p_url) {
      this.clickImpress(p_id)
      this.openURL(p_url)
    },
    openURL(p_url) {
      window.open(p_url, '_blank')
    },
    clickImpress(p_id) { // pripocitaj vzhliadnutie
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/videonavody/${p_id}/impress`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'put',
      }
      axios(config)
        .then(resp => {
          this.video.impress = resp.data.new_impress
        })
        .catch(err => {
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snack_color = 'red'
          this.snackbar = true
        })
    },
    voteRating(p_id, p_val) {
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/videonavody/${p_id}/rating`,
        data: JSON.stringify({
          rating: p_val,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'put',
      }
      axios(config)
        .then(resp => {
          this.video.rating = resp.data.new_rating
          this.snack_text = 'Váš hlas byl započítán. Děkujeme!'
          this.snack_color = 'primary'
          this.snackbar = true
        })
        .catch(err => {
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snack_color = 'red'
          this.snackbar = true
        })
    },
    onEditFormVideonavody(p_id) {
      this.$emit('onEdit', { open: true, item_id: p_id }) // otvor dialog na create/update videa
    },
    onDeleteFormVideonavody(p_id) {
      this.item_id = p_id
      this.open_dialog_zmazat = true
    },
    deleteFormVideonavody() {
      this.open_dialog_zmazat = false
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/videonavody/${this.item_id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'delete',
      }
      axios(config)
        .then(resp => {
          this.$emit('onDelete', {}) // otvor dialog na create/update videa
          this.snack_text = 'Video bylo odstraněno.'
          this.snackbar = true
        })
        .catch(err => {
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snackbar = true
        })
    },
  },
}
</script>

<style>
.play-icon {
  opacity: 0.1;
}
.play-icon:hover {
  opacity: 1;
}
.play-icon:hover + .play-img {
  opacity: 1;
}
.play-img {
  opacity: 0.6;
}
.play-img:hover {
  opacity: 1;
}
</style>
