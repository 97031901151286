<template>
  <div>
    <!-- LOADING -->
    <v-progress-linear
      v-if="loading"
      indeterminate
    ></v-progress-linear>

    <!-- FILTER + STRANKOVANIE -->
    <v-row>
      <v-col
        sm="6"
        cols="12"
      >
        <v-select
          v-model="category"
          :items="categories"
          label="Kategorie"
          @change="handleFilter()"
        ></v-select>
      </v-col>
      <v-col
        sm="6"
        cols="12"
      >
        <div style="float:right">
          <v-pagination
            v-model="fetch_settings.paging.current_page"
            :length="fetch_settings.paging.total_pages"
            :total-visible="10"
            circle
            @input="handlePaging"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <div
      style="position:relative"
    >
      <button
        type="button"
        class="v-btn v-btn--absolute v-btn--fab v-btn--has-bg v-btn--right v-btn--round v-btn--top theme--light v-size--default primary"
        title="Vložit článek"
        @click="open_form_videonavody=true"
      >
        <span
          class="v-btn__content"
        >
          <span
            aria-hidden="true"
            class="v-icon notranslate theme--light"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              role="img"
              aria-hidden="true"
              class="v-icon__svg"
            >
              <path
                d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"
              >
              </path>
            </svg>
          </span>
        </span>
      </button>
      <v-row
        v-for="(record, index) in videa"
        :key="record._id + '_' + index"
        dense
      >
        <v-col
          sm="6"
          cols="12"
        >
          <video-card
            v-if="index % 2 === 0"
            :video="videa[index]"
            @onEdit="onEditFormVideonavody(...arguments)"
            @onDelete="onCloseFormVideonavody(...arguments)"
          >
          </video-card>
        </v-col>
        <v-col
          sm="6"
          cols="12"
        >
          <video-card
            v-if="index % 2 === 0"
            :video="videa[index+1]"
            @onEdit="onEditFormVideonavody(...arguments)"
            @onDelete="onCloseFormVideonavody(...arguments)"
          >
          </video-card>
        </v-col>
      </v-row>
    </div>

    <form-videonavody
      :show="open_form_videonavody"
      :title="`${item_id ? 'Upravit video' : 'Vložit nové video'}`"
      :item_id="item_id"
      @close="onCloseFormVideonavody(...arguments)"
    >
    </form-videonavody>

    <!-- SNACKBAR -->
    <snack-bar
      :snackbar="snackbar"
      :color="snack_color"
      :text="snack_text"
      @close="snackbar=false"
    >
    </snack-bar>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: ["off", { "allow": ["__place"] }] */
/* eslint quote-props: ["off", "always"] */
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiCartPlus,
  mdiPencil,
  mdiTrashCan,
  mdiShareVariantOutline,
  mdiLockOpenOutline,
  mdiStarOutline,
  mdiAccountOutline,
  mdiTrendingUp,
  mdiHelpCircleOutline,
} from '@mdi/js'
import axios from 'axios'
import latinize from 'latinize'
import SnackBar from '@/components/snackbar/index.vue'
import FormVideonavody from '@/components/form-videonavody/index.vue'
import response from '@/mixins/response'
import Video from './components/Video.vue'

export default {
  components: {
    'snack-bar': SnackBar,
    'video-card': Video,
    'form-videonavody': FormVideonavody,
  },
  mixins: [response],
  data() {
    return {
      // icons
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiCartPlus,
        mdiPencil,
        mdiTrashCan,
        mdiShareVariantOutline,
        mdiLockOpenOutline,
        mdiStarOutline,
        mdiAccountOutline,
        mdiTrendingUp,
        mdiHelpCircleOutline,
      },
      loading: true,
      desktop_w: 1024,
      desktop_h: 768,
      snackbar: false,
      snack_color: 'red',
      snack_text: '',
      videa: [],
      category: 'Vše',
      categories: ['Vše', 'Chat', 'Hireme', 'Inzerce', 'Neurčeno'],
      open_form_videonavody: false,
      item_id: '',
      fetch_settings: {
        filter: {
          active: true,
          kategorie: this.category === 'Vše' ? undefined : this.category,
        },
        paging: {
          limit: 6,
          skip: 0,
          current_page: 1,
          total_pages: 1,
          total_records: 1,
        },
        sort: {
          datum: -1,
        },
      },
    }
  },
  watch: {
    '$store.state.global.filter_search': {
      handler(_newVal) {
        const filter = this.buildFilter(latinize(_newVal).toLowerCase())
        this.fetchVideonavody(filter)
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    window.addEventListener('resize', this.windowResize)
    this.windowResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResize)
  },
  mounted() {
    this.fetchVideonavody()
  },
  methods: {
    windowResize() {
      this.desktop_w = window.innerWidth
      this.desktop_h = window.innerHeight
    },
    handleFilter() {
      this.fetch_settings.filter.kategorie = this.category === 'Vše' ? undefined : this.category
      this.fetchVideonavody()
    },
    buildFilter(_search) {
      const filter = { // default
        active: true,
        kategorie: this.category === 'Vše' ? undefined : this.category,
      }
      if (_search && _search.length > '') {
        const search = { $regex: `${_search}`, $options: 'i' }
        filter.$or = [
          { latin_nazev: search },
          { latin_text: search },
        ]
      }

      return filter
    },
    fetchVideonavody(p_filter) {
      this.loading = true
      this.fetch_settings.filter = p_filter || this.fetch_settings.filter
      const body = JSON.stringify(this.fetch_settings)
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/videonavody/index`,
        data: body,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
      }
      axios(config)
        .then(resp => {
          this.videa = resp.data.db_data
          this.loading = false
          this.fetch_settings.paging.total_pages = resp.data.total_pages
        })
        .catch(err => {
          this.loading = false
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snack_color = 'red'
          this.snackbar = true
        })
    },
    handlePaging(p_page) {
      // vypocitaj hodnotu skip a potom zavolaj nove nacitanie dat z DB
      const { limit } = this.fetch_settings.paging
      this.fetch_settings.paging.skip = (limit * p_page) - limit
      this.fetchVideonavody()
    },
    onEditFormVideonavody(p_data) {
      this.item_id = p_data.item_id
      this.open_form_videonavody = p_data.open
    },
    onCloseFormVideonavody(p_param) {
      this.open_form_videonavody = false
      this.item_id = undefined
      if (p_param.refresh !== false) {
        this.fetchVideonavody()
      }
    },
  },
}
</script>

<style>
  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
</style>
